<template>
  <div>
    <div class="condition">
      <el-input
        class="condition_input content_item"
        placeholder="请输入设备码或关键字"
        v-model="deviceCode"
        clearable
      ></el-input>
      <el-select
        v-model="status"
        class="content_state content_item"
        placeholder="请选择处理状态"
        clearable
      >
        <el-option
          v-for="item in stateItme"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <span class="warning_time_title">巡检时间</span>
      <el-date-picker
        prefix-icon="el-icon-date"
        format="yyyy-MM-dd HH:mm:ss"
        value-format="yyyy-MM-dd HH:mm:ss"
        v-model="startTime"
        type="datetime"
        placeholder="选择开始日期时间"
      >
      </el-date-picker>
      <span style="padding:4px">-</span>
      <el-date-picker
        class="content_item"
        prefix-icon="el-icon-date"
        v-model="endTimes"
        value-format="yyyy-MM-dd HH:mm:ss"
        format="yyyy-MM-dd HH:mm:ss"
        type="datetime"
        placeholder="选择结束日期时间"
      >
      </el-date-picker>
      <el-button class="query" @click="Query()">查询</el-button>
      <el-button class="import" @click="importDatas">导出</el-button>
    </div>
    <div class="table">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="shopName" label="店名" width="180">
        </el-table-column>
        <el-table-column prop="deviceFeatureName" label="设备型号" width="180">
        </el-table-column>
        <el-table-column prop="assetCode" label="资产编码"> </el-table-column>
        <el-table-column prop="deviceImei" label="IMEI"> </el-table-column>
        <el-table-column prop="inspectionUserName" label="巡检人">
        </el-table-column>
        <el-table-column prop="createTime" label="巡检时间"> </el-table-column>
        <el-table-column label="巡检单状态">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.status == 1">已处理</el-tag>
            <el-tag type="warning" v-if="scope.row.status == 2">未处理</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="inspectionUserPosition" label="巡检位置">
        </el-table-column>
        <el-table-column prop="assetCode" label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              class="edit el-icon-edit"
              size="small"
              @click="particulars(scope.row, true)"
            >
              编辑
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="app-page-container">
      <span @click="first()">首页</span>
      <el-pagination
        @current-change="handleCurrentChange"
        layout="pager"
        :hide-on-single-page="false"
        :page-size="12"
        :current-page="currentPage"
        :total="recordCount"
      >
      </el-pagination>
      <span @click="last()">尾页</span>
    </div>
  </div>
</template>

<script>
import { getList, exportData } from "../../../api/abnormalAdmin/particulars";
export default {
  name: "index",
  data() {
    return {
      pages: 1,
      pageSize: 12, //每页数据条数
      recordCount: 0,
      currentPage: 1, //当前页数
      deviceCode: "",
      state: "",
      startTime: "",
      endTimes: "",
      organizationName: "",
      deepId: "",
      tableData: [],
      status: "",
      stateItme: [
        {
          value: "1",
          label: "已处理"
        },
        {
          value: "2",
          label: "未处理"
        }
      ]
    };
  },
  created() {
    this.Query();
  },
  methods: {
    //获取数据列表
    Query() {
      let data = {
        userId: localStorage.getItem("userId"),
        token: localStorage.getItem("token"),
        deptId: localStorage.getItem("deptId"), //部门ID
        curPage: this.pages,
        limit: 12,
        status: this.status,
        keyWord: this.deviceCode,
        startCreateTime: this.startTime,
        endCreateTime: this.endTimes
      };
      getList(data).then(res => {
        this.tableData = res.data.data.records;
        this.recordCount = res.data.data.total;
        console.log(this.tableData);
      });
    },
    //导出
    importDatas() {
      this.$store.commit("SET_LOADING", true);
      let data = {
        userId: localStorage.getItem("userId"),
        token: localStorage.getItem("token"),
        deptId: localStorage.getItem("deptId"), //部门ID
        curPage: this.pages,
        limit: 12,
        status: this.status,
        keyWord: this.deviceCode,
        startCreateTime: this.startTime,
        endCreateTime: this.endTimes
      };
      exportData(data)
        .then(res => {
          const dt = new Date();
          const y = dt.getFullYear();
          const m = (dt.getMonth() + 1 + "").padStart(2, "0");
          const d = (dt.getDate() + "").padStart(2, "0");
          const hh = (dt.getHours() + "").padStart(2, "0");
          const mm = (dt.getMinutes() + "").padStart(2, "0");
          const ss = (dt.getSeconds() + "").padStart(2, "0");
          let date = y + m + d + hh + mm + ss;
          const content = res.data; //返回的内容
          let _this = this;
          if (res.data.type !== "application/vnd.ms-excel") {
            //二进制转字符串
            let reader = new FileReader();
            reader.addEventListener("loadend", function(e) {
              // 输出字符串 转化为json对象
              let str = JSON.parse(e.target.result);
              _this.$message({
                showClose: true,
                message: str.desc,
                type: "error"
              });
              if (str.resultCode == 2000) {
                _this.$router.push("/login");
              }
            });
            reader.readAsText(content);
          } else {
            const fileName = "资产信息列表" + date.toString() + ".xls"; //下载文件名
            //处理下载流
            const blob = new Blob([content]); //创建一个类文件对象：Blob对象表示一个不可变的、原始数据的类文件对象
            const url = window.URL.createObjectURL(blob); //URL.createObjectURL(object)表示生成一个File对象或Blob对象
            let dom = document.createElement("a"); //设置一个隐藏的a标签，href为输出流，设置download
            dom.style.display = "none";
            dom.href = url;
            dom.setAttribute("download", fileName); //指示浏览器下载url,而不是导航到它；因此将提示用户将其保存为本地文件
            document.body.appendChild(dom);
            dom.click();
          }
        })
        .finally(() => {
          this.$store.commit("SET_LOADING", false);
        });
    },
    //跳转详情页
    particulars(data) {
      console.log(data, "datassss");
      this.$router.push({
        path: "/dashboard/abnormityAdmin/particulars",
        query: { ...data }
      });
    },
    handleCurrentChange(val) {
      this.pages = val;
      this.Query();
      console.log(`当前页: ${val}`);
    },
    //分页首页
    first() {
      this.tableData = [];
      this.currentPage = 1;
      this.Query();
    },
    //分页最后一页
    last() {
      this.currentPage = this.recordCount;
      this.pages = Math.ceil(this.recordCount / 12);
      this.Query();
    }
  }
};
</script>

<style scoped>
/*分页样式*/
.app-page-container {
  margin-top: 20px;
}
.app-page-container .el-pagination {
  display: inline-block;
}
.app-page-container span {
  cursor: pointer;
  color: #616161;
  background: #fff;
  border: 1px solid #e8eaf0;
  height: 32px;
  line-height: 32px;
  display: inline-block;
  text-align: center;
  transition: 0.1s;
  vertical-align: top;
  border-radius: 16px;
  font-size: 12px;
  width: 56px;
}
.app-page-container span:hover {
  background: #00cccc;
  color: #fff;
  border-color: #00cccc;
}
.edit {
  font-size: 14px;
  color: #6e7790;
}

.edit:hover {
  color: #0cc;
}
.table {
  margin-top: 20px;
}
.warning_time_title {
  font-family: PingFangSC-Regular;
  font-size: 13px;
  color: #3d3d3d;
  letter-spacing: -0.04px;
  margin-right: 5px;
}
.content_item {
  margin-right: 10px;
}
.content_state {
  width: 150px;
}
.condition_input {
  width: 246px;
  height: 42px;
}
</style>
